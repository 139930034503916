@use "variables";

* {
  margin: 0px;
  padding: 0px;
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
  list-style: none;
  font-size: 15px;
  ::selection {
    background-color: #89fdea; /* Set your desired highlight color */
    color: #0d1b34; /* Set the text color for the selected text */
  }
}

:root {
  --tab-height: 50px;
  --tab-width: 150px;
  --exp-item-index: 0;
}

html {
  scroll-behavior: smooth;
}

:root {
  --dark: #10132a;
  --white: #ffffff;
}

.darkMode {
  --dark: #10132a;
}

body {
  background-color: var(--dark);
  overflow-x: hidden;
  overflow-y: auto;
}

/* Hide the scrollbar */
body.no-scroll {
  overflow-y: hidden;
}

/* For Webkit based browsers */
::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: variables.$accent; /* Set the color of the thumb */
  border-radius: 0px; /* Set the border radius of the thumb */
}

/* For Firefox */
/* Note: Firefox currently supports a limited number of scrollbar properties */
/* You can use the scrollbar-color property to set the color of the thumb and track */
/* Example:
   scrollbar-color: #888 #f1f1f1;
*/

/* For Internet Explorer and Edge */
/* Note: Internet Explorer and Edge have limited support for scrollbar styling */
/* You can use the -ms-overflow-style property to set the scrollbar style */
/* Example:
   -ms-overflow-style: none; (to hide the scrollbar)
   -ms-overflow-style: auto; (to display the default scrollbar)
   -ms-overflow-style: scrollbar; (to display a custom scrollbar)
*/

.pageWrapper {
  // width: 100%;
  // height: auto;
  --left: 0px;
  --top: 0px;
  padding: 0px 150px;
}

// .pageWrapper::before {
//   content: "";
//   border-radius: 50%;
//   width: 70rem;
//   height: 70rem;
//   position: absolute;
//   left: var(--left);
//   top: var(--top);
//   transform: translate(-50%, -50%);
//   z-index: -900;
//   transition-duration: 50ms;
//   // radial-gradient(600px at 717px 523px, rgba(29, 78, 216, 0.15), transparent 80%)
//   background: radial-gradient(
//     circle,
//     rgba(29, 78, 216, 0.15) 0%,
//     rgba(29, 78, 216, 0.07) 40%,
//     rgba(29, 78, 216, 0) 70%
//   );

//   opacity: 0;
// }

// .pageWrapper:hover::before {
//   opacity: 0.8;
// }

.socialHandleContainer {
  position: fixed;
  bottom: 0px;
  left: 50px;
}

.fixedEmailContainer {
  width: 40px;
  position: fixed;
  bottom: 0px;
  left: auto;
  right: 40px;
  z-index: 10;
  color: variables.$light002;
}

.fixedEmailContainer ::after {
  content: "";
  display: block;
  width: 1px;
  height: 90px;
  margin: 0px auto;
  background-color: variables.$light002;
}

.fixedEmailInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  a {
    margin: 20px auto;
    padding: 10px;
    font-size: 14px;
    letter-spacing: 0.2em;
    writing-mode: vertical-rl;

    display: inline-block;
    text-decoration: none;
    // text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    transition: all 0.2s ease;
  }
}

.fixedEmailInnerContainer a:hover {
  color: variables.$accent;
}

.lineDrawn {
  width: 1px;
  height: 150px;
  background-color: variables.$light002;
}
.socialHandle {
  margin-bottom: 20px;
}

// #linkedinIcon {
//   stroke: variables.$light005;
//   transition: transform 0.3s ease; /* Adding a smooth transition effect */

//   &:hover {
//     stroke: variables.$accent;
//   }
// }

// #githubIcon {
//   stroke: variables.$light005;
//   transition: transform 0.3s ease; /* Adding a smooth transition effect */

//   &:hover {
//     stroke: variables.$accent;
//   }
// }

// #instagramIcon {
//   stroke: variables.$light005;
//   transition: transform 0.3s ease; /* Adding a smooth transition effect */

//   &:hover {
//     stroke: variables.$accent;
//   }
// }

.socialHandle img {
  width: 20px;
  height: 20px;
}

.socialHandle span {
  visibility: hidden;
  display: none;
}

.socialList {
  list-style: none;
  display: flex;
  // list-style: none;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.cursor {
  width: 20px;
  height: 20px;
  border: 1px solid variables.$accent;
  border-radius: 50%;
  position: absolute;
  transition-duration: 50ms;
  transition-timing-function: ease-out;
  animation: cursorAnim 0.5s infinite alternate;
  pointer-events: none;
}

.cursor::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  border: 8px solid gray;
  border-radius: 50%;
  opacity: 0.5;
  top: -8px;
  left: -8px;
  animation: cursorAnim2 0.5s infinite alternate;
}

@keyframes cursorAnim {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.7);
  }
}

@keyframes cursorAnim2 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.4);
  }
}

@keyframes cursorAnim3 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.expand {
  animation: cursorAnim3 0.5s forwards;
  border: 1px solid variables.$accent;
}

.button {
  user-select: none;
  display: flex;
  // width: 291px;
  height: 60px;
  background-color: transparent;
  padding: 13.308px 44px;
  justify-content: center;
  align-items: center;
  gap: 7.828px;
  flex-shrink: 0;
  letter-spacing: 2px;
  display: flex;
  padding: 5px 40px;
  background-color: #10132a33;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 300;
  cursor: pointer;
  border: 1px solid rgba($color: variables.$accent, $alpha: 0.4);
  color: variables.$accent;
  transition: transform 0.2s ease, background-color 0.2s ease, border 0.2s ease;
}
.button:hover {
  background-color: #89fdea22;
  transform: translateY(-5px) scale(1, 1.05);
  box-shadow: 0px 10px 49px -11px rgba(0, 0, 0, 0.261);
  border: none;
}

.button:active {
  background-color: #89fdea47;
}

footer {
  // background-color: cadetblue;
  // height: 200px;
  // margin-top: 400px;
  padding: 100px;
  color: variables.$light007;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  line-height: 25px;

  .footerDiv {
    max-width: 400px;
  }
  a {
    text-decoration: none;
    color: variables.$light002;
  }
}

@media (pointer: coarse), (max-width: variables.$themeWidth) {
  .cursor {
    display: none;
  }
  .spotLight {
    display: none;
  }
  .pageWrapper {
    padding: 0px 100px;
  }
  .socialHandleContainer {
    left: 20px;
  }
  .fixedEmailContainer {
    right: 20px;
  }
}

@media (pointer: coarse), (max-width: variables.$tabWidth) {
  .socialHandleContainer {
    display: none;
  }
  .fixedEmailContainer {
    display: none;
  }
}

@media (max-width: variables.$tabWidth) {
  .pageWrapper {
    padding: 0px 50px;
  }

  footer {
    padding: 25px;
    padding-bottom: 100px;
    .footerDiv {
      max-width: 400px;
      // background-color: #89fdea;
    }
  }
}

@media (max-width: variables.$mobileWidth) {
  .pageWrapper {
    padding: 0px 20px;
  }
}
